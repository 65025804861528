import React, { useState, useEffect, useRef } from "react";
import "./PricingPlans.css";

const baseurl = `https://backend.aeriestec.com/`;
 
const imageUrls = [
  {
    imageUrl:
      "https://res.cloudinary.com/ddjsaoac6/image/upload/v1733408397/plane5_wz6lev.jpg",
    imageAlt: "plane5",
  },
  {
    imageUrl:
      "https://res.cloudinary.com/ddjsaoac6/image/upload/v1733408360/plane2_gt3g1z.jpg",
    imageAlt: "plane2",
  },
  {
    imageUrl:
      "https://res.cloudinary.com/ddjsaoac6/image/upload/v1733408372/plane3_sapxxx.jpg",
    imageAlt: "plane3",
  },
  {
    imageUrl:
      "https://res.cloudinary.com/ddjsaoac6/image/upload/v1733408349/plane1_wvszx8.jpg",
    imageAlt: "plane1",
  },
];
 
const PricingPlans = () => {
  const [data, setData] = useState([]);
  const containerRef = useRef(null);
 
  // Fetch PDF data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch( `${baseurl}/get/files`);
        const result = await response.json();
        console.log(result);
        setData(result);
      } catch (error) {
        console.error("Error fetching PDF data:", error);
      }
    };
 
    fetchData();
  }, []);
 
  // Scroll the container to the top when data changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [data]);
 
  // const openFile = async (id) => {
  //   try {
  //     const response = await fetch(`http://localhost:3005/view/file/${id}`);
  //     if (!response.ok) {
  //       throw new Error(`Error fetching file: ${response.statusText}`);
  //     }
  //     const fileBlob = await response.blob();
  //     const fileUrl = URL.createObjectURL(fileBlob);
  //     window.open(fileUrl, "_blank");
  //   } catch (error) {
  //     console.error("Error opening file:", error);
  //   }
  // };
 
  const openFile = async (id) => {
    console.log(id);
 
    const a = `${baseurl}/view/file/${id}`; // Backend endpoint to fetch image URL
    const b = { method: "GET" };
 
    const c = await fetch(a, b);
    if (c.ok) {
      const d = await c.text();
      console.log("D", d);
      const imageUrl = `${baseurl}/${d}`;
      console.log(imageUrl); // Image URL from backend
      window.open(imageUrl, "_blank"); // Opens the image URL in a new tab
    } else {
      alert("Failed to fetch the image");
    }
 
    // try {
    //   const response = await fetch(`http://localhost:3005/view/file/${id}`);
 
    //   if (!response.ok) {
    //     throw new Error(`Error fetching file: ${response.statusText}`);
    //   }
 
    //   // const imageUrl = `http://localhost:3005/${response}`;
    //   // window.open(imageUrl, "_blank");
 
    //   //Check if the content type is PDF
    //   const contentType = response.headers.get("Content-Type");
    //   if (contentType !== "application/pdf") {
    //     throw new Error("Invalid file type. Expected a PDF.");
    //   }
 
    //   const fileBlob = await response.blob();
    //   const fileUrl = URL.createObjectURL(fileBlob);
 
    //   // Open the PDF in a new tab
    //   window.open(fileUrl, "_blank");
    // } catch (error) {
    //   console.error("Error opening file:", error);
    //   alert("Unable to open the file. Please try again.");
    // }
  };
 
  return (
    <section id="pricing-plans">
      <h2>Investment Pricing Plans</h2>
      <div className="pricing-cards">
        {/* Pricing cards remain the same */}
        <div className="card basic-card">
          <img
            className="plans-card-image"
            src="https://res.cloudinary.com/dabzdwxet/image/upload/v1733830435/invest-plan-basic_u8nb4l.jpg"
            alt="basic"
          />
 
          <div>
            <h3>Basic</h3>
            <p>₹1000/month</p>
          </div>
        </div>
        <div className="card advance-card">
          <img
            className="plans-card-image"
            src="https://res.cloudinary.com/dabzdwxet/image/upload/v1733830435/invest-plan-advance_vxfjpl.jpg"
            alt="advance"
          />
 
          <h3>Advance</h3>
          <p>₹2100/month</p>
        </div>
        <div className="card exclusive-card">
          <img
            className="plans-card-image"
            src="https://res.cloudinary.com/dabzdwxet/image/upload/v1733830435/invest-plan-exclusive_wblvil.jpg"
            alt="exclusive"
          />
 
          <h3>Exclusive</h3>
          <p>₹3600/month</p>
        </div>
      </div>
 
      <div className="pricing-slider-carousel">
        <div className="image-slider">
          {imageUrls.map((eachItem, index) => (
            <img
              key={index}
              src={eachItem.imageUrl}
              alt={eachItem.imageAlt}
              className="slider-image"
            />
          ))}
        </div>
 
        <div id="pricing-plans" className="pricing-plans-files-container">
          <div className="updates-logo-container">
            <img
              src="https://res.cloudinary.com/dabzdwxet/image/upload/v1733742585/invest-updates-icon_f4jyjc.jpg"
              alt="updates-logo"
              className="updates-logo-image"
            />
            <h2>Our Capital Partners List</h2>
          </div>
 
          <div className="abcd" ref={containerRef}>
            {data.map((file) => (
              <button
                key={file.investment_id}
                onClick={() => openFile(file.investment_id)}
                className="file-name-button pricing-files-update scrollable-container"
              >
                {file.topic}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
 
export default PricingPlans;
 